import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The PayPerks team was honored to be a part of the recent conference "`}<a parentName="p" {...{
        "href": "http://www.d2dfund.org/plsconference"
      }}>{`More than Fun & Games`}</a>{`," the first of what we know will be many successful prize-linked savings (PLS) summits. The conference, hosted by the `}<a parentName="p" {...{
        "href": "http://www.d2dfund.org/"
      }}>{`Doorways 2 Dreams Fund`}</a>{` (D2D Fund) in Boston, MA, gathered thought leaders from academia to business to government to promote PLS innovations and explore ways to increase its prevalence in the US.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.d2dfund.org/our_work/building_savings/prize_linked_savings"
      }}>{`Prize-linked savings`}</a>{` is the concept of leveraging the excitement of sweepstakes-based games in order to fuel savings behaviors.`}</p>
    <p>{`It’s no surprise that we are huge believers in the power of prize-linked savings. After all, it’s at the core of what we do at PayPerks. But I left the conference even more convinced than ever that 1) we NEED to change American’s savings behaviors; and 2) PLS is the way to do so. Here are some of the statistics that reinforced my convictions, cited by `}<a parentName="p" {...{
        "href": "http://www.sbs.ox.ac.uk/research/people/Pages/PeterTufano.aspx"
      }}>{`Peter Tufano`}</a>{`:`}</p>
    <p><strong parentName="p">{`We have a huge savings problem`}</strong></p>
    <ul>
      <li parentName="ul">{`1/4 of Americans have no emergency savings`}</li>
      <li parentName="ul">{`1/2 of Americans could not come up with \\$2,000 if given 30 days to do so`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5ElEQVQoz4VRW28SURDen9iHvvlia9PGa+QSwppGqPrSt+I/aJ/UmvShpD4QYmKFEEBIatQsxUKNiywse/bC2V3OOTNjWqEaXvwymUxm5vsyF42WgDgdDKamGVpWaFnRaMRNU/j+n9JSr4ZLKUTheTPPE0EQe+7UtoPRSIWhknK2gJRyTp5LXhkhKkR5ozMTwrLtkW2jUv54PLSswWBgmiZjbE4OWp/iOI6JFJEvHU+O/8r9D5pYWbH393+9Ofx5fNQvHfXfF4efW3EUCyInihTAzToEgAubk9XaGm5vUyqt7m/x5GbwaJ3fvjV59Xp4cmIcHDDT5FEklJL/7kNEAISoyY0Ntburnr8Q2XSYS4XZhyLzGHN50HVKJHjhpVso2Ht77O2hc1y0ez270xkZxvUoqOHqKjzRMZVW97ai5Gb8YF3evUNPc6DrkExSLo+ZDKVSpGcpkYizWfFsR+R3wlIJLEtzy+XZ2VncbnuVU7v6bnxa9CofeKPJ6/WgWg1bLb9WYx8rfr3h1Wq8UeftNm82/XIZGNMiRAEwQ4iRphB6MoiRQgSuFAcIATgAl4ortYivvgxESKR9+/rle7fbMYyLbrd/0Ts3zn9cXrqMMYc5k4nrMHbtXcfxXJdNJsxx5tdG/A240keIhJISjwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/20f965deb083b29c722bbdc473a3bc04/7f61c/2000savings.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/20f965deb083b29c722bbdc473a3bc04/b0a15/2000savings.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/20f965deb083b29c722bbdc473a3bc04/e17e5/2000savings.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/20f965deb083b29c722bbdc473a3bc04/0b533/2000savings.png 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/20f965deb083b29c722bbdc473a3bc04/0b533/2000savings.png",
            "alt": "2000savings",
            "title": "2000savings",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p><strong parentName="p">{`Americans are addicted to sweepstakes-based games`}</strong></p>
    <ul>
      <li parentName="ul">{`In 2009, Americans spend \\$200/yr on the lottery per capita
(this per capita figure includes every man, woman and child)`}</li>
      <li parentName="ul">{`In MA and DE, per capita lottery spend is $670 and $773 respectively`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBUlEQVQoz22SPWhTURTHL1Skk4vioCAucXNxEGc3B0VEUXDQqYj4NUgzdRHroILgByoI0qlU6lJFilooBasUh0ppG5ti6kuaxCTvvvvy8j7uPfecI0kaSWz+/Kdz7+8cOOcvXE+5UrmeX625yg/AkgHsZ2vAaoPcJbGZz0kprbXGACK1isSMLVumzm9qPoFFoi645DhBGG4hbbVB2qoQogVgtKwTQKIOTcSCmGwcNWcV1+LM8dA/QfFp0NcSnU7cUW9lMa7XE0u5Jw8Xxl4Bc7UmC4V8NptthKFgItjYYIPe5OuFI6K8JCI+H7w5Vjkp6mUxM33o/d07+Pzp5E7xcWqKmYMg9JXyPAkAghGp4XPEya/05qdb9eqcHxXV6nX3nZB/jn67nXqb2j22d2Di4tm86wGQ66pSseg4+ThJBDPbMEDN7I03gssqtEFwVTVEAKLODzL3Hk0fEOP7d3weGQbmJDHQXK3RSdKazIxoMYzY6KQ85BZ3aRaV2cH1UeGVhlYf3/996cLswX0ry0vMHGuoSeU4Tja75nlKdF2CEBjkh8LPm5kbh7/vGfhxZnDxRWr52cv5c6dkpczMBmzPqbpu3uSJCC0HRbk+fGVmJF31K5pZzs/lvn5hZm2AOvoPbndoZYPZRLFSPvcEYFtIuJ9ajQnAEOK/ePWBLVJ/W8JtRQ098F8gGLb5R32AoQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/130a251eab8e60a4ff4b41ba865a7f56/7f61c/lotterysales.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/130a251eab8e60a4ff4b41ba865a7f56/b0a15/lotterysales.webp 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/130a251eab8e60a4ff4b41ba865a7f56/e17e5/lotterysales.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/130a251eab8e60a4ff4b41ba865a7f56/0b533/lotterysales.png 500w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/130a251eab8e60a4ff4b41ba865a7f56/0b533/lotterysales.png",
            "alt": "lotterysales",
            "title": "lotterysales",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`If you'd like to learn more about PLS, the presentations from the conference are available for you to view `}<a parentName="p" {...{
        "href": "http://www.d2dfund.org/agenda"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      